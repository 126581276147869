import React, {useState} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import "../index.css";
import '../firebaseui-styling.global.css';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { EmailAuthProvider, GoogleAuthProvider, signInAnonymously } from 'firebase/auth';
import { useAuth } from '../firebase/auth';
import { auth } from '../firebase/firebase';
import Authenticator from "../models/Authenticator";

export default function Login({handleShowToast}) {
    const { authUser, signOut } = useAuth();
    const navigate = useNavigate();
    const { redirect } = useParams();
    const analytics = getAnalytics();

    let REDIRECT_PAGE = '/';
    if (redirect !== undefined) {
        if (redirect === "info") {
            REDIRECT_PAGE = "/sellTickets/info";
        } else if (redirect === "buy") {
            REDIRECT_PAGE = "/discover";
        }
    }

    // think of how you want to sing up guests and the permissions they have (especially for selling and the accounts page)
    const SingInAnonymously = () => {
        signInAnonymously(auth)
        .then(() => {
            console.log("user anonymously signed in");
            navigate(REDIRECT_PAGE);
            window.scrollTo(0, 0);
        })
        .catch((error) => {
            console.log(error.message);
        })
    }


    const uiConfig = {
        signInFlow: 'popup',
        signInSuccessUrl: REDIRECT_PAGE,
        autoUpgradeAnonymousUsers: true,
        signInOptions: [
            {
            provider: EmailAuthProvider.PROVIDER_ID,
            requireDisplayName: true
            },

            {
            provider: GoogleAuthProvider.PROVIDER_ID,
            customParameters: {
                prompt: 'select_account'
            }
            },
            {
                provider: "apple.com",
                providerName: "Apple",
                buttonColor: "black",
                loginHintKey: "login_hint",
                scopes: [
                    "email",
                    "name"
                ]
            }
        ],
        tosUrl: 'https://entick.co.uk/terms',
        privacyPolicyUrl: 'https://entick.co.uk/privacy',
        callbacks: {
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                if (authResult.additionalUserInfo.isNewUser) {
                    handleShowToast("User created successfully", "success");
                    Authenticator.emailVerification(authResult.user).then((response) => {
                        Authenticator.createUser(authResult.user.displayName, authResult.user.uid).then((response) => {
                            if (response.error) {
                                handleShowToast("Error when fetching your full name", "failure");
                            }
                            else {
                                logEvent(analytics, 'sign_up');
                            }
                        })
                    })
                }
                if (redirect === "info") {
                    handleShowToast("This might take a few seconds", "success");
                }
                navigate(REDIRECT_PAGE);
                window.scrollTo(0, 0);
                logEvent(analytics, 'login');

            },
            signInFailure: (error) => {
                handleShowToast("Error creating user", "error");
            }

        }
    };

    if (!authUser || auth.currentUser.isAnonymous) {
        return (
            <section className="login main" id="login-container">
                <div className="login-container">
                    <h1 className="login-title">Welcome to Entick</h1>
                    <p className="login-description">Login or create an account with the options below</p>
                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
                    <span>Or</span>
                    <div className="guest-sign-in-container">
                        <button className="guest-button" onClick={SingInAnonymously}>Continue as a Guest</button>
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <section className="login main">
                <div className="login-container">
                    <h1 className="login-title">You are Logged in</h1>
                    <p className="login-description">Click the button below to log out</p>
                    <button className="login-button" type="button" onClick={signOut} >Log out</button>
                </div>
            </section>
        )
    }
}