import React from "react";
import "../index.css";
import { FastIcon, ShieldIcon, HandshakeIcon, RunningManIcon, HandsIcon } from "./Icons/Icons";

export default function Features({buy}) {
  if (buy) {
    return (
        <section>
            <div className='cards' id="features">
              <div className="feature">
                <span className="feature-no" id="one"><FastIcon/></span>
                <h2 className='card-title'>Quick</h2>
                <p className='feature-description'>Buy second-hand tickets for nightclubs with ease. Simply click buy tickets and find your event.</p>
              </div>
              <div className="feature">
                <span className="feature-no" id="two"><ShieldIcon/></span>
                <h2 className='card-title'>Secure</h2>
                <p className='feature-description'>Our anti-fraud system keeps the marketplace secure and offers the safest payment methods.</p>
              </div>
              <div className="feature">
                <span className="feature-no" id="three"><HandsIcon/></span>
                <h2 className='card-title'>Effortless</h2>
                <p className='feature-description'>Our interface allows sellers to list their extra tickets in just a few clicks.</p>
              </div>
            </div>
          </section>
    )
  } else {
    return (
      <section>
            <div className='cards' id="features">
              <div className="feature">
                <span className="feature-no" id="one"><HandshakeIcon/></span>
                <h2 className='card-title'>Easy</h2>
                <p className='feature-description'>Post your listing and let us handle all the logistics. We will notify you once your listing is sold.</p>
              </div>
              <div className="feature">
                <span className="feature-no" id="two"><RunningManIcon/></span>
                <h2 className='card-title'>Fast</h2>
                <p className='feature-description'>Once your tickets are sold the money will be sent to your account the next working day.</p>
              </div>
              <div className="feature">
                <span className="feature-no" id="three"><HandsIcon/></span>
                <h2 className='card-title'>Effortless</h2>
                <p className='feature-description'>It only takes a few clicks to post your first listing. Click start selling now!</p>
              </div>
            </div>
          </section>
    )
  }
}