import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { config } from "../index"


export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);


  const handleSubmit = async (event) => {
    setButtonDisabled(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
      return;
    }

    const {error} = await stripe.confirmPayment({
    //`Elements` instance that was used to create the Payment Element
    elements,
    confirmParams: {
        return_url: config.checkout_url,
    },
    });
    if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        setButtonDisabled(false);
        setErrorMessage(error.message);
        } else {
          setButtonDisabled(false);
            // Show a success message to your customer
        //   Your customer will be redirected to your `return_url`. For some payment
        //   methods like iDEAL, your customer will be redirected to an intermediate
        //   site first to authorize the payment, then redirected to the `return_url`.
        }
};



  return (
    <form className='checkout-form' onSubmit={handleSubmit}>
      <PaymentElement />
      {errorMessage && <div className='error-message'>{errorMessage}</div>}
      <button className="hero-button-o" id="pay-button" type="submit" disabled={!stripe || buttonDisabled}>Complete order</button>
      <button className="back-button" disabled={!stripe || buttonDisabled} onClick={() => navigate("/cart")}>Back</button>
    </form>
  )
}