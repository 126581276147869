import React, {useEffect, useState, } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router,Routes, Route} from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import { getValue, fetchAndActivate } from 'firebase/remote-config';
import { remoteConfig } from './firebase/firebase'
import "./index.css"

import Header from './components/Header';
import Footer from './components/Footer';
import Login from './components/Login';
import Cart from './components/Cart';
import Checkout from './components/Checkout';
import ToastList from './components/ToastList/ToastList';
import Modal from './components/Modal/Modal';
import SellerCheck from './components/SellerCheck';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import MobileContent from './components/MobileContent';
import NotFound from './components/NotFound';
import MobileUrl from './components/MobileUrl';
import { AuthUserProvider } from './firebase/auth';
import Skeleton from './components/Skeleton/Skeleton';
import Maintenance from './components/Maintenance/Maintenance';

const MainContent = React.lazy(() => import('./components/MainContent'));
const Discover = React.lazy(() => import('./components/Discover'));
const Event = React.lazy(() => import('./components/Event'));
const Profile = React.lazy(() => import('./components/Profile'));
const CreateEvent = React.lazy(() => import('./components/CreateEvent'));
const SellTickets = React.lazy(() => import('./components/Selling/SellTickets'));
const Sell = React.lazy(() => import('./components/Sell'));
const LocationDiscover = React.lazy(() => import('./components/LocationDiscover'));

const root = ReactDOM.createRoot(document.getElementById('root')); 

function App() {
  // Toast States
  const [toasts, setToasts] = useState([]);
  const [autoCloseDuration, setAutoCloseDuration] = useState(5);
  const [position, setPosition] = useState("bottom-right");

  // Toast Functions
  const showToast = (message, type) => {
      const toast = {
          id: Date.now(),
          message,
          type,
        };
      
      setToasts((prevToasts) => [...prevToasts, toast]);
      
      setTimeout(() => {
        removeToast(toast.id);
      }, autoCloseDuration * 1000);
  }

  const removeToast = (id) => {
      setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  // const removeAllToasts = () => {
  //     setToasts([]);
  // };
  
  // const handleAutoCloseDurationChange = (e) => {
  //     setAutoCloseDuration(Number(e.target.value));
  // };

  // const handlePositionChange = (event) => {
  //     setPosition(event.target.value);
  // };

  // Modal States
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalHeader, setModalHeader] = useState(null);


  // Modal Functions

function handleShowModal(children, header) {
    setModalContent(children);
    setModalHeader(header);
    setShowModal(true);
    var main = document.getElementsByClassName("main-content")[0];
    main.style.opacity = "0.5";
}

function closeModal() {
    var main = document.getElementsByClassName("main-content")[0];
    main.style.opacity = "1";
    setShowModal(false);
}

let val = null;
// function GetKillData() {
//   fetchAndActivate(remoteConfig).then(() => {
//     val = getValue(remoteConfig, "kill");
//     console.log("killing");
//     console.log(val);
// });
// }

// useEffect(() => {
//   if (!val) {
//     GetKillData();
//   }
// }, [])

const reload = () => window.location.reload();

if (val && val._value === "true") {
  return <KillPage/>
} else {
  return (
    <Router>
      <AuthUserProvider>
        <div className='main-content'>
          <div>
            <Header/>
          </div>
          <BrowserView>
          <Routes>
          <Route path="/" element={
          <React.Suspense fallback={<Skeleton Type="circular" />}>
            <MainContent />
            </React.Suspense>} />
            <Route path="/discover" element={
            <React.Suspense fallback={<Skeleton Type="circular" />}>
              <Discover handleShowModal={handleShowModal} handleCloseModal={closeModal}/>
              </React.Suspense>} />
            <Route path="/event/:id" element={<React.Suspense fallback={<Skeleton Type="circular" />}>
              <Event handleShowToast={showToast} handleShowModal={handleShowModal} handleCloseModal={closeModal}/>
              </React.Suspense>} />
            <Route path="/profile/:section" element={<React.Suspense fallback={<Skeleton Type="circular" />}>
              <Profile handleShowToast={showToast} hShowModal={handleShowModal} hCloseModal={closeModal}/>
            </React.Suspense>} />
            <Route path="/login" element={<Login handleShowToast={showToast}/>} />
            <Route path="/login/:redirect" element={<Login handleShowToast={showToast}/>} />
            <Route path="/newevent" element={<React.Suspense fallback={<Skeleton Type="circular" />}>
              <CreateEvent handleShowToast={showToast}/>
            </React.Suspense>} />
            {/* <Route path="/event/:id/newlisting" element={<CreateListing handleShowToast={showToast}/>} /> */}
            <Route path="/cart" element={<Cart handleShowToast={showToast}/>} />
            <Route path="/cart/checkout" element={<Checkout handleShowToast={showToast}/>} />
            <Route path="/sell" element={<React.Suspense fallback={<Skeleton Type="circular" />}>
            <Sell handleShowToast={showToast}/>
            </React.Suspense>} />
            <Route path="/sellTickets/:stage" element={
              <React.Suspense fallback={<Skeleton Type="circular"/>}>
            <SellTickets handleShowToast={showToast}/>
            </React.Suspense>} />
            <Route path="/sellTickets/:stage/:key" element={
              <React.Suspense fallback={<Skeleton Type="circular"/>}>
            <SellTickets handleShowToast={showToast}/>
            </React.Suspense>} />
            <Route path="/sellerCheck" element={<SellerCheck handleShowToast={showToast}/>} />
            <Route path="/location/:loc" element={<React.Suspense fallback={<Skeleton Type="circular"/>}>
            <LocationDiscover handleShowModal={handleShowModal} handleCloseModal={closeModal}/>
            </React.Suspense>} />
            <Route path="/terms" element={<Terms/>} />
            <Route path="/privacy" element={<Privacy/>} />
            <Route path="/apple-app-site-association" onEnter={reload} />
            <Route path="/.well-known/apple-developer-merchantid-domain-association" />
            <Route path="/.well-known/assetlinks.json" onEnter={reload} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          </BrowserView>
          <MobileView>
            <Routes>
              <Route path="/" element={<MobileContent />} />
              <Route path="/terms" element={<Terms/>} />
              <Route path="/privacy" element={<Privacy/>} />
              <Route path="/return_url" element={<MobileUrl/>} />
              <Route path="/refresh_url" element={<MobileUrl/>} />
              <Route path="/account" element={<Profile handleShowToast={showToast} hShowModal={handleShowModal} hCloseModal={closeModal}/>} />
              <Route path="/apple-app-site-association" onEnter={reload} />
              <Route path="/.well-known/apple-developer-merchantid-domain-association" onEnter={reload} />
              <Route path="/.well-known/assetlinks.json" onEnter={reload} />
            </Routes>
            </MobileView>
          <Footer />
        </div>
        <ToastList data={toasts} position={position} removeToast={removeToast} />
        <Modal show={showModal} handleClose={closeModal} header={modalHeader}>
          {modalContent}
        </Modal>
      </AuthUserProvider>
    </Router>
  )
}

}

function KillPage() {
  return (
    <Router>
      <div className='main-content'>
        <BrowserView>
        <Routes>
          <Route path='*' element={<Maintenance />}></Route>
        </Routes>
        </BrowserView>
        <MobileView>
          <Routes>
            <Route path='*' element={<Maintenance />}></Route>
          </Routes>
        </MobileView>
      </div>
    </Router>
  )
}

export const config = {
  return_url: 'http://entick.co.uk/sellerCheck',
  checkout_url: 'http://entick.co.uk/cart/checkout',
  stripe_promise: 'pk_test_51MRYnhKDi5Owm7gVf12Z41RZnYkdmaXDfNTymHD9XMmQQ0LfehrkwNxhOTopNe8j2slkMV8BumRzbeuCuFF4QEwQ00PWl2CzTR'
}

root.render(
  <App/>
);

