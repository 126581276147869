import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {Elements, ExpressCheckoutElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import CheckoutForm from './CheckoutForm';
import PaymentStatus from "./PaymentStatus";
import ListingFetchModel from "../models/getData/ListingFetchModel";
import { useAuth } from "../firebase/auth";
import Skeleton from "./Skeleton/Skeleton";

// use constants
const stripePromise = loadStripe('pk_test_51MRYnhKDi5Owm7gVf12Z41RZnYkdmaXDfNTymHD9XMmQQ0LfehrkwNxhOTopNe8j2slkMV8BumRzbeuCuFF4QEwQ00PWl2CzTR');

export default function Checkout({handleShowToast}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [total, setTotal] = useState(5);
    const [allTickets, setAllTickets] = useState([]);
    const { authUser, isLoading } = useAuth();
    let urlPaymentIntent = searchParams.get("payment_intent");
    let urlClientSecret = searchParams.get("payment_intent_client_secret");

    const getTotal = () => {
        ListingFetchModel.fetchCartItems(authUser).then((response) => {
            if (response.success) {
                let tickets = [];
                setTotal(response.total);
                for (let i = 0; i < response.data.length; i++) {
                    for (let j = 0; j < response.data[i].tickets.length; j++) {
                        tickets.push(response.data[i].tickets[j]);
                    }
                }
                setAllTickets(tickets);
            }
        })
    }

    let theme = "";
    if (document.body.classList.contains("light")) {
        theme = "flat";
    } else {
        theme = "night";
    }

    const options = {
        clientSecret: urlClientSecret,
        appearance: {
            theme: theme,
        },
      };

      useEffect(() => {
        if (authUser) {
            if (total === 0 || allTickets.length === 0) {
                getTotal();
            }
        }
      }, [authUser, total, allTickets])


      if (urlPaymentIntent) {
        return (
            <Elements stripe={stripePromise} options={options.clientSecret}>
                {allTickets.length > 0 ? <PaymentStatus user={authUser} item={options.clientSecret} handleShowToast={handleShowToast} tickets={allTickets}/> : <Skeleton Type="circular"/>}
            </Elements>
        )
        } else if (urlClientSecret !== "") {
        return (
            <section className="main">
                <div className="event-page">
                    <div className="cart-top">
                        <h1 className="cart-title">Payment</h1>
                        <p className="cart-description">The tickets can be downloaded under the My Tickets section in your profile.</p>
                    </div>
                    <div className="cart">
                        <Elements stripe={stripePromise} options={options}>
                            <CheckoutForm />
                        </Elements>
                        <div className="cart-checkout-container">
                        <div className="cart-checkout">
                            <span className="cart-checkout-total">Total: £{total.toFixed(2)}</span>
                            {/* Add a question mark where once you hover over/click on it you see the price breakdown with the fees */}
                            <span className="cart-checkout-description">the total includes the Entick fees</span>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            );
        } else {
            return (
                <Skeleton Type="circular"/>
            )
        }
    };