import { updateDoc, getDoc, doc, setDoc} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { db } from "../firebase/firebase";
import {deleteUser, getAuth, sendEmailVerification } from "firebase/auth";
import ListingPostModel from "./postData/ListingPostModel";


export default class Authenticator {
    
    static async createUser(name, uid) {
        try {
            if (!uid) {
                return {
                    success: false,
                    error: "No uid provided"
                }
            }
            if (!name) {
                return {
                    success: false,
                    error: "No name provided"
                }
            }

            let firstAndLastName = [];
            try {
                firstAndLastName = name.split(' ');    
            } catch (error) {
                firstAndLastName = [name, ""];
            }

            await setDoc(doc(db, "user", uid), {
                firstName: firstAndLastName[0],
                lastName: firstAndLastName[1],
            }, { merge: true });

            
            return {
                success: true,
                error: null
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    static async addEmail(email, uid) {
        try {
            if (!uid) {
                return {
                    success: false,
                    error: "No uid provided"
                }
            }


            await setDoc(doc(db, "user", uid), {
                firstName: "Guest",
                lastName: "GuestSon",
                email: email
            }, { merge: true });

            
            return {
                success: true,
                error: null
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    // static async createUserWithEmail(name, email, password) {
    //     try {
    //         const auth = getAuth();
    //         const firstAndLastName = name.split(' ');

    //         const response = await createUserWithEmailAndPassword(auth, email, password)

    //         return {
    //             success: true,
    //             data: {
    //                 name: name,
    //                 uid: response.user.uid
    //             }
    //         }
    //     } catch (error) {
    //         return {
    //             success: false,
    //             error: error
    //         }
    //     }
    // }

    static async getUserName(uid) {
        try {
            const userDoc = await getDoc(doc(db, "user", uid));
            const userData = userDoc.data();
            if (userData) {
                return {
                    success: true,
                    data: {
                        firstName: userData.firstName,
                        username: userData.username,
                        avgRating: userData.avgRating,
                        numReviews: userData.numReviews
                    }
                }
            } else {
                return {
                    success: false,
                    error: "User data not found"
                }
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    static async getUserData(user) {
        try {
            if (user) {
                const userDoc = await getDoc(doc(db, "user", user.uid));
                const userData = userDoc.data();
                if (userData) {
                    return {
                        success: true,
                        data: {
                            firstName: userData.firstName,
                            lastName: userData.lastName,
                            seller: userData.seller,
                            termsAccepted: userData.termsAccepted,
                            email: userData.email,
                            connectedAccount: userData.connectedAccount,
                            connectedAccountId: userData.connectedAccountId,
                            stripeCustomerId: userData.stripeCustomerId,
                            avgRating: userData.avgRating,
                            numReviews: userData.numReviews,
                            username: userData.username
                        }
                    }
                } else {
                    return {
                        success: false,
                        error: "User data not found"
                    }
                }
            } else {
                return {
                    success: false,
                    error: "User not signed in"
                }
            }
        } catch (error) {
            return {
                success: false,
                error: "Error getting user data"
            }
        }
    }

    static async isSeller(user) {
        try {
            const response = await this.getUserData(user);
            if (response.success) {
                if (response.data.seller) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    static async startSelling(user) {
        try {
            if (user) {
                await updateDoc(doc(db, "user", user.uid), {
                    seller: true
                });

                return {
                    success: true,
                    data: null
                }
            } else {
                return {
                    success: false,
                    error: "User not signed in"
                }
            }
        } catch (error) {
            return {
                success: false,
                error: "Error starting to sell"
            }
        }
    }



    static async acceptTerms(user) {
        try {
            if (user) {
                await updateDoc(doc(db, "user", user.uid), {
                    termsAccepted: true
                });
                return {
                    success: true
                }
            } else {
                return {
                    success: false,
                    error: "User not signed in"
                }
            }
        } catch (error) {
            return {
                success: false,
                error: "Error accepting terms"
            }
        }
    }

    static async updateUsername(user, username) {
        try {
            if (user) {
                await updateDoc(doc(db, "user", user.uid), {
                    username: username
                });
                return {
                    success: true
                }
            } else {
                return {
                    success: false,
                    error: "User not signed in"
                }
            }
        } catch (error) {
            return {
                success: false,
                error: "Error updating username"
            }
        }
    }

    static async emailVerification(user) {
        try {
            if (!user) {
                return {
                    success: false,
                    error: "User not signed in"
                }
            }
            
            const auth = getAuth();
            sendEmailVerification(auth.currentUser).then(() => {
                return {
                    success: true
                }
            }).catch((error) => {
                console.log(error);
            })

            return {
                success: true
            }

        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    static async deleteAccount() {
        // This is not working
        const auth = getAuth();
        const user =  auth.currentUser;

        if (!user) {
            return {
                success: false,
                error: "User not signed in"
            }
        }

        const cartRes = await ListingPostModel.emptyCart(user);
        if (!cartRes.success) {
            return {
                success: false,
                error: "Failed to empty cart"
            }
        }
        const func = getFunctions();
    
        const userRef = await getDoc(doc(db, "user", user.uid));
        const {connectedAccount, connectedAccountId} = userRef.data();

        if (connectedAccountId !== "") {
            const res =  await httpsCallable(func, 'deleteConnectedAccount')({id: connectedAccountId});
            if (!res.data.deleted) {
                return {
                    success: false,
                    error: "Failed to delete connected account. This is likely due to a pending balance"
                }
            }
        } else {
            await setDoc(doc(db, "user", user.uid), {
                connectedAccount: false,
                connectedAccountId: ""
            }, { merge: true });
        }

        try {
            const response = await deleteUser(user)
            return {
                success: true,
                data: response
            }
        } catch (error) {
            return {
                success: false,
                error: "error deleting user"
            }
        }
    }
}