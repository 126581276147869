import React from "react";
import ListingPostModel from "../models/postData/ListingPostModel";
import { DeleteIcon } from "./Icons/Icons";
import "../index.css";
import { CalendarIcon, LocationDot } from "./Icons/Icons";

export default function CheckoutItem(props) {

    const removeCartItem = async () => {
        ListingPostModel.removeFromCart(props.user, props.item).then((response) => {
            if (response.success) {
                ListingPostModel.resetTimer(props.user).then((response) => {
                    if (response.success) {
                        props.handleShowToast("Removed from cart", "success");
                        props.handleRefresh();
                    } else {
                        props.handleShowToast("Something went wrong", "failure");
                        console.log(response.error);
                    }
                })
            } else {
                props.handleShowToast("Something went wrong", "failure");
            }
        })
    }

    let startTime = new Date(props.item.eventStart);
    let time = startTime.toLocaleString().slice(0, -3);

    return (
        <div className="checkout-item">
            <div className="checkout-item-info">
                <h2 className="event-face-title" id="checkout-item-title">{props.item.tickets.length}x {props.item.eventTitle}</h2>
                <span className="cart-date"><CalendarIcon/> {time} - <LocationDot /> {props.item.venue}</span>
                <span >£{props.item.price}/ticket</span>
            </div>
            <button className="icon--lg icon-btn-o" onClick={removeCartItem}><DeleteIcon/></button>
        </div>
    )
}