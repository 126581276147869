import React from 'react';
import '../index.css';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as AppleSvg } from '../images/apple-badge.svg';
import {isBrowser, isMobile} from 'react-device-detect';

export default function Footer() {
  const navigate = useNavigate();

  function navigateTo(url) {
    navigate(url);
    window.scrollTo(0, 0);
  }
  if (isBrowser) {
    return (
      <footer >
        <div className="footer">
          <div className="footer-left">
            <h2 className="footer-title">Menu</h2>
            <ul className="footer-list">
              <a href='/'><li className="footer-item">Home</li></a>
              <li className="footer-item" onClick={() => navigateTo("/login")}>Sign up</li>
              <li className="footer-item" onClick={() => navigateTo("/discover")}>Events</li>
              <li className="footer-item" onClick={() => navigateTo("/profile/account")}>Profile</li>
              <li className="footer-item" onClick={() => navigateTo("/profile/mytickets")}>My Tickets</li>
              <li className="footer-item" onClick={() => navigateTo("/profile/mylistings")}>My Listings</li>
              <li className="footer-item" onClick={() => navigateTo("/sell")}>Start selling</li>
            </ul>
          </div>
          <div className="footer-middle">
          <h2 className="footer-title">Socials</h2>
            <ul className="footer-list">
              <a href='/'><li className="footer-item">Facebook</li></a>
              <a href='https://www.instagram.com/entickltd/'><li className="footer-item">Instagram</li></a>
              <a href='https://twitter.com/EntickLtd'><li className="footer-item">X</li></a>
              <a href='https://tiktok.com/entick_marketplace'><li className="footer-item">TikTok</li></a>
            </ul>
          </div>
          <div className="footer-right">
            <h2 className="footer-title">Download our App</h2>
            <ul className="footer-list">
              <a href='/'><li className="footer-item">
                  <AppleSvg />
                </li></a>
              <a href='/'><li className="footer-item"><img className="badge" src={require("../images/google-play-badge.png")} alt="google-play-store-badge"></img></li></a>
            </ul>
          </div>
        </div>
      </footer> 
    ) } else if (isMobile) {
      return (
        <footer >
        <div className="mobile-footer">
          <div className="mobile-footer-middle">
          <h2 className="footer-title">Socials</h2>
            <ul className="mobile-footer-list">
              <a href='/'><li className="footer-item">Facebook</li></a>
              <a href='https://www.instagram.com/entickltd/'><li className="footer-item">Instagram</li></a>
              <a href='https://twitter.com/EntickLtd'><li className="footer-item">X</li></a>
              <a href='/'><li className="footer-item">TikTok</li></a>
            </ul>
          </div>
          <div className="footer-right">
            <h2 className="footer-title">Download our App</h2>
            <ul className="footer-list">
              <a href='/'><li className="footer-item">
                  <AppleSvg />
                </li></a>
              <a href='/'><li className="footer-item"><img className="badge" src={require("../images/google-play-badge.png")} alt="google-play-store-badge"></img></li></a>
            </ul>
          </div>
        </div>
      </footer>
      )
  }
}