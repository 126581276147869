import { getDoc, doc, collection, updateDoc, where, orderBy, query, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import {config} from "../../index"
import { getFunctions, httpsCallable } from "firebase/functions";

export default class Transaction {

    static async getPaymentIntentClientSecret(amount, currency) {
        try {
            const funct = getFunctions();
            const response = await httpsCallable(funct, 'createPaymentIntent')({amount, currency});
            return {
                success: true,
                data: response.data
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    static async buyCart(user) {
        try {
            if (!user) {
                return {
                    success: false,
                    error: "User not logged in"
                }
            }

            const funct = getFunctions();
            const response = await httpsCallable(funct, 'buyUserCart')({user: user.uid});
            return {
                success: response.data.success,
                error: response.data.message
            }

        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    
    static async getPayouts(user) {
        try {
            if (!user) {
                return {
                    success: false,
                    error: "User not logged in"
                }
            }
            let payoutsQuery = await query(collection(db, "payouts"), where("to", "==", user.uid), orderBy("payoutTime", "desc"));
            const payoutsQuerySnapshot = await getDocs(payoutsQuery);

            let data = [];
            payoutsQuerySnapshot.forEach((doc) => {
                const { amount, to, createdAt, status } = doc.data();
                data.push({
                    id: doc.id,
                    amount: amount,
                    to: to,
                    createdAt: new Date(createdAt.seconds * 1000),
                    status: status
                });
            });
            return {
                success: true,
                data: data
            };
        } catch (error) {
            return {
                success: false,
                error: "Error getting payouts"
            }
        }
    }
            

    static async connectedAccountLink(email) {
        if (email) {
            try {
                const funct = getFunctions();
                const response = await httpsCallable(funct, 'createConnectedAccount')({email: email, return_url: config.return_url, refresh_url: config.return_url}); 
                return {
                    success: true,
                    account: response.data.id,
                    link: await response.data.url
                }
            } catch (error) {
                return {
                    success: false,
                    error: error
                }
            }
        } else {
            return {
                success: false,
                error: "wrong email"
            }
        }
    }

    static async connectedAccountOnboardingLink(accountId) {
        try {
            const funct = getFunctions();
            const response = httpsCallable(funct, 'fetchAccountOnboardingLink')({id: accountId, refresh_url: config.return_url, return_url: config.return_url});

            return {
                success: true,
                link: (await response).data.url
            }
        } catch (error) {
            return {
                success: false,
                error: "Error fetching onboarding link"
            }
        }
    }

    static async getConnectedAccount(accountId) {
        try {
            const funct = getFunctions();
            const response = httpsCallable(funct, 'fetchConnectedAccount')({id: accountId});

            try {
                const data = (await response).data;
                if (data.details_submitted === true) {
                const bankName = data.external_accounts.data[0].bank_name;
                const last4 = data.external_accounts.data[0].last4;
                const sortCode = data.external_accounts.data[0].routing_number;
                
                return {
                    success: true,
                    account: {
                        id: accountId,
                        chargesEnabled: data.charges_enabled,
                        detailsSubmitted: data.details_submitted,
                        accountDetails: {
                            bankName,
                            last4,
                            routingNumber: sortCode
                        }
                    }
                }
            } else {
                return {
                    success: false,
                    account: {
                        id: accountId,
                        chargesEnabled: false,
                    }
                }
            }

            } catch (error) {
                return {
                    success: false,
                    account: {
                        id: accountId,
                        chargesEnabled: false,
                    }
                }
            }
        } catch (error) {
            return {
                success: false,
                error: "Error getting account"
            }
        }
    }

    static async setConnectedAccountId(user, id) {
        try {
            if (!user) {
                return {
                    success: false,
                    error: "User not logged in"
                }
            }

            await updateDoc(doc(db, "user", user.uid), {
                connectedAccount: id
            });

            return {
                success: true,
            };
        } catch (error) {
            return {
                success: false,
                error: "Error setting account id"
            }
        }
    }

    static async verifyConnectedAccount(user, accountId) {
        try {
            if (!user) {
                return {
                    success: false,
                    error: "User not logged in"
                }
            }

            await updateDoc(doc(db, "user", user.uid), {
                connectedAccountId: accountId,
                connectedAccount: false
            });

            return {
                success: true,
            };
        } catch (error) {
            return {
                success: false,
                error: "Error verifying account"
            }
        }
    }

    static async completeConnectedAccountVerification(user, accountId) {
        try {
            if (!user) {
                return {
                    success: false,
                    error: "User not logged in"
                }
            }

            await updateDoc(doc(db, "user", user.uid), {
                connectedAccountId: accountId,
                connectedAccount: true
            });

            return {
                success: true,
            };
        } catch (error) {
            return {
                success: false,
                error: "Error verifying account"
            }
        }
    }



    static async isConnectedAccountVerified(user) {
        try {
            if (!user) {
                return {
                    success: false,
                    error: "User not logged in"
                }
            }

            let userRef = doc(db, "user", user.uid);
            let userDoc = await getDoc(userRef);
            if (userDoc.exists()) {
                let userData = userDoc.data();
                if (userData.connectedAccount) {
                    return {
                        success: true,
                        verified: true
                    }
                } else {
                    return {
                        success: true,
                        verified: false,
                        accountId: userData.connectedAccountId
                    }
                }
            } else {
                return {
                    success: false,
                    error: "User not found"
                }
            }
        } catch (error) {
            return {
                success: false,
                error: "Error verifying account"
            }
        }
    }

    static async createACheckoutSession(tickets, customerId) {
        try {
            const funct = getFunctions();
            const response = await httpsCallable(funct, 'createCheckoutSession')({tickets, customerId, success_url: "http://localhost:3000/success", cancel_url: "http://localhost:3000/cancel"});

            return {
                success: true,
                sessionUrl: response.url
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }
    
}