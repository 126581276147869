import React from "react";
import { CloseIcon } from "../Icons/Icons";
import './Modal.css';

const Modal = ({ handleClose, show, children, header }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";
  
    return (
      <div className={showHideClassName}>
        <section className="modal-main">
            <div className="modal-header">
                <h3 className="modal-title">{header}</h3>
                <button className="modal-close-btn" onClick={handleClose}>
                  <div className="icon--xl mar-left">
                    <CloseIcon />
                  </div>
                </button>
            </div>
            {children}
        </section>
      </div>
    );
  }

export default Modal;