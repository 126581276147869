import React from "react";
import "./Skeleton.css";
import { CircularProgress } from "@mui/material";
import { CalendarIcon, LocationDot } from '../Icons/Icons';

export default function Skeleton({ Type }) {

    const Circular = () => {
        return (
            <main className="main">
                <div className="circular">
                    <CircularProgress color="secondary"/>
                </div>
            </main>
        )
    }

    const LogoSkeleton = () => {
        return (
            <div className="logo-skeleton">
                <div className="logo-skeleton-title">Entick Marketplace</div>
                <div className="logo-skeleton-text">Checking details...</div>
            </div>
        )
    }

    const EventSkeleton = () => {
        return (
            <section className="event-hero">
                <div className="event-imagesk"></div>
                <div className="event-titlesk"></div>
                <div className="event-infosk">
                    <div className="event-datesk"></div>
                    <div className="event-datesk"></div>
                </div>
                <div className="event-descriptionsk"></div>
            </section>
        )
    }

    const PaymentSkeleton = () => {
        return (
            <div className="payouts-containersk">
                <div className="bank-details-containersk">
                    <h3 className="event-box-titlesk">Bank Details</h3>
                    <div className="bank-detailssk">
                        <div className="bank-details-itemsk">
                            <p className="bank-details-textsk"></p>
                            <p className="bank-details-textsk"></p>
                        </div>
                        <div className="bank-details-itemsk">
                            <p className="bank-details-textsk"></p>
                            <p className="bank-details-text"></p>
                        </div>
                        <div className="bank-details-itemsk">
                            <p className="bank-details-textsk"></p>
                            <p className="bank-details-textsk"></p>
                        </div>
                        <div className="bank-details-btn-itemsk">
                            <div className="icon icon-btn-osk"></div>
                        </div>
                    </div>
                </div>
                <div className="bank-details-containersk">
                    <h3 className="event-box-titlesk">Payouts</h3>
                    <div className="bank-detailssk">
                    </div>
                </div>
            </div>
        )
    }
    if (Type === "payment") {
        return <PaymentSkeleton />
    } else if (Type === "circular") {
        return <Circular />
    } else if (Type === "logo") {
        return <LogoSkeleton />
    } else if (Type === "event") {
        return <EventSkeleton />
    }
    else {
        return null;
    }
}