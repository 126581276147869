import { doc, setDoc, serverTimestamp, deleteDoc, addDoc, collection, getDoc, increment, updateDoc, arrayRemove, arrayUnion, where, query, getDocs } from "firebase/firestore";
import { getStorage, ref, uploadBytes, deleteObject } from "firebase/storage";
import { db } from "../../firebase/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import EventFetchModel from "../getData/EventFetchModel";
// import PdfParse from "pdf-parse";

async function hashTicket(ticket) {
    let hashHex = "";
    try {
        const file = await readUploadedFileAsText(ticket);
        const encoder = new TextEncoder();
        const fileBuffer = encoder.encode(file);
        const hashBuffer = await crypto.subtle.digest('SHA-1', fileBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
        return hashHex;
    } catch (error) {
        return hashHex;
    }
}

function checkTicket(ticket, eventName) {
    const reader = new FileReader();

    // reader.onload = async (e) => {
    //   const contents = e.target.result;
    //   const pdf = await PDFDocument.load(contents);
    //   const pages = pdf.getPages();
    //   let extractedText = '';

    //   for (const page of pages) {
    //     const textContent = await page.getTextContent();
    //     const pageText = textContent.items.map((item) => item.str).join(' ');
    //     extractedText += pageText;
    //   }

    //     if (!extractedText.includes(eventName)) {
    //         throw new Error("Ticket does not match event");
    //     }

    // };

    reader.readAsArrayBuffer(ticket);
}

const readUploadedFileAsText = (inputFile) => {
    const temporaryFileReader = new FileReader();
  
    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };
  
      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsText(inputFile);
    });
  };

function calcPrice(price) {
    const rawPrice = price * 100;
    const newPrice = rawPrice * 1.06;
    const fee = rawPrice * 0.12;
    return {
        price: newPrice,
        rawPrice: rawPrice,
        fee: fee
    }
}

export default class ListingPostModel {

    static async newListing(listing) {
        try {
            // Change it to batch write
            let user = listing.user;
            if (!user) {
                return {
                    success: false,
                    error: "User not logged in"
                }
            };

            if (!listing.tickets || listing.tickets.length === 0) {
                return {
                    success: false,
                    error: "No tickets uploaded"
                }
            }
            const res = await EventFetchModel.fetchEvent(listing.eventId);
            if (res.success) {
                listing.expiry = res.data.expiry;
            } else {
                return {
                    success: false,
                    error: "Event not found"
                }
            }

            checkTicket(listing.tickets[0], res.data.name);

            const {price, rawPrice, fee} = calcPrice(listing.price);
            if (listing.description && listing.price && listing.eventId && listing.type && listing.isSeller && listing.expiry) {
                const docRef = await addDoc(collection(db, "listing"), {
                    description: listing.description,
                    rawPrice: rawPrice,
                    price: price,
                    type: listing.type,
                    event: listing.eventId,
                    expiry: new Date(listing.expiry + 60 * 60 * 1000),
                    tickets: [],
                    verified: false,
                    createdBy: user.uid,
                    createdAt: serverTimestamp(),
                    inCart: 0,
                    sold: 0,
                    fee: fee
                });
                const id = docRef.id;

                try {
                    let tickets = [];
                    const storage = getStorage();
                    for (let i = 0; i < listing.tickets.length; i++) {
                        const ticket = listing.tickets[i];
                        let ticketRef = ref(storage, `tickets/${id}/${i}.${ticket.name.split(".").pop()}`);
                        var hash = await hashTicket(ticket);
                        if (hash === "") {
                            return {
                                success: false,
                                error: "Error hashing ticket"
                            }
                        }
                        await uploadBytes(ticketRef, ticket);
                        await setDoc(doc(db, "hashes", hash), {
                            path: ticketRef.fullPath,
                            allowed: ""
                        });
                        tickets.push(ticketRef.fullPath);
                    }
                    await setDoc(doc(db, "listing", id), {
                        tickets: tickets
                    }, { merge: true });

                    const functions = getFunctions();
                    const response = httpsCallable(functions, 'verifyListing');
                    response({
                        id: id
                    })

                    await updateDoc(doc(db, "event", listing.eventId), {
                        tickets: increment(listing.tickets.length)
                    });

                    return {
                        success: true,
                        data: id
                    };

                } catch (error) {
                    listing.id = id;
                    await deleteDoc(doc(db, "listing", id));
                    return {
                        success: false,
                        error: "Error uploading tickets"
                    }
                }

            } else {
                return {
                    success: false,
                    error: "Invalid listing data"
                }
            }
        }
        catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    static async deleteListing(listing, user) {
        try {
            // change it to batch write
            const listingId = listing.key;
            if (!user) {
                return {
                    success: false,
                    error: "User not logged in"
                }
            };

            const listingRef = doc(db, "listing", listingId);
            const listingDoc = await getDoc(listingRef);
            if (listingDoc.exists()) {
                const listingData = listingDoc.data();
                if (listingData.createdBy === user.uid) {
                    let functions = getFunctions();
                    const response = httpsCallable(functions, 'unverifyListing');
                    response({
                        id: listingId
                    });
                    
                    const storage = getStorage();
                    listingData.tickets.forEach(async (ticket) => {
                        await deleteObject(ref(storage, ticket));
                    });

                    await deleteDoc(listingRef);

                    // Test delete hashes out
                    for (let i = 0; i < listingData.tickets.length; i++) {
                    await getDocs(query(collection(db, "hashes"), where("path", "==", listingData.tickets[i]))).then((querySnapshot) => {
                        deleteDoc(doc(db, "hashes", querySnapshot.docs[0].id));
                    });
                    }

                    await updateDoc(doc(db, "event", listingData.event), {
                        tickets: increment(-listingData.tickets.length)
                    });

                    return {
                        success: true,
                    };
                } else {
                    return {
                        success: false,
                        error: "User not authorized"
                    }
                }
            } else {
                return {
                    success: false,
                    error: "Listing not found"
                }
            }
        } catch (error) {
            return {
                success: false,
                error: "Error deleting listing"
            }
        }
    }

    static async editListing(listing) {
        try {
            const {price, rawPrice, fee} = calcPrice(listing.price);
            await updateDoc(doc(db, "listing", listing.id), {
                price: price,
                fee: fee,
                rawPrice: rawPrice
            });
            return {
                success: true
            };
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    static async addToCart(user, listing, quantity) {
        try {
            if (!user) {
                return {
                    success: false,
                    error: "User not logged in"
                }
            };
            const listingRef = doc(db, "listing", listing.key);
            const listingDoc = await getDoc(listingRef);
            if (listingDoc.exists()) {
                const listingData = listingDoc.data();
                if (listingData.createdBy !== user.uid) {
                    const ticketsToBuy = listingData.tickets.slice(0, quantity);
                    await updateDoc(listingRef, {
                        tickets: arrayRemove(...ticketsToBuy),
                        inCart: increment(quantity)
                    });
                    const eventRef = doc(db, "event", listingData.event);
                    const eventSnapShot = await getDoc(eventRef);
                    if (eventSnapShot.exists()) {
                        const eventData = eventSnapShot.data();
                        try {
                            await addDoc(collection(db, "user", user.uid, "cart"), {
                                listing: listing.key,
                                event: listingData.event,
                                type: listingData.type,
                                tickets: ticketsToBuy,
                                price: listingData.price,
                                fee: listingData.fee,
                                eventTitle: eventData.name,
                                eventStart: eventData.start,
                                venue: eventData.venue,
                                seller: listingData.createdBy
                            });
                        } catch (error) {
                            await updateDoc(listingRef, {
                                tickets: arrayUnion(...ticketsToBuy),
                                inCart: increment(-quantity)
                            });
                            return {
                                success: false,
                                error: "Error adding to cart"
                            }
                        }

                        await updateDoc(doc(db, "event", listingData.event), {
                            tickets: increment(-quantity)
                        });
                    } else {
                        return {
                            success: false,
                            error: "Event not found"
                        }
                    }
                    return {
                        success: true,
                    };
                } else {
                    return {
                        success: false,
                        error: "User not authorized, you can't buy your own tickets"
                    }
                }
            } else {
                return {
                    success: false,
                    error: "Listing not found"
                }
            }
        } catch (error) {
            return {
                success: false,
                error: "Error adding to cart"
            }
        }
    }

    static async clearCart(user, cartItems) {
        try {
            if (!user) {
                return {
                    success: false,
                    error: "User not logged in"
                }
            }
            for (let i = 0; i < cartItems.length; i++) {
                const response = await this.removeFromCart(user, cartItems[i]);
                if (!response.success) {
                    return {
                        success: false,
                        error: response.error
                    }
                }
            }
            return {
                success: true
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    static async emptyCart(user) {
        try {
            if (!user) {
                return {
                    success: false,
                    error: "User not logged in"
                }
            }

            const cartItems = await getDocs(query(collection(db, "user", user.uid, "cart")));
            const cartItemsData = cartItems.docs;
            for (let i = 0; i < cartItemsData.length; i++) {
                const response = await this.removeFromCart(user, cartItemsData[i]);
                if (!response.success) {
                    return {
                        success: false,
                        error: response.error
                    }
                }
            }
            return {
                success: true
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }


    static async setTimer(user) {
        try {
            await setDoc(doc(db, "timer", user.uid), {
                startAt: serverTimestamp(),
                seconds: 600,
            });

            return {
                success: true
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    static async resetTimer(user) {
        try {
            await setDoc(doc(db, "timer", user.uid), {
                startAt: serverTimestamp(),
                seconds: 0,
            });
            return {
                success: true
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    static async removeFromCart(user, cartItem) {
        try {
            if (!user) {
                return {
                    success: false,
                    error: "User not logged in"
                }
            }
            let cartRef = doc(db, "user", user.uid, "cart", cartItem.key);
            let cartDoc = await getDoc(cartRef);
            if (cartDoc.exists()) {
                let cartData = cartDoc.data();
                let listingRef = doc(db, "listing", cartData.listing);
                let listingDoc = await getDoc(listingRef);
                if (listingDoc.exists()) {
                    await updateDoc(listingRef, {
                        tickets: arrayUnion(...cartData.tickets),
                        inCart: increment(-cartData.tickets.length)
                    });
                    await deleteDoc(cartRef);
                    await updateDoc(doc(db, "event", cartItem.event), {
                        tickets: increment(cartData.tickets.length)
                    });
                    return {
                        success: true,
                    };
                } else {
                    return {
                        success: false,
                        error: "Listing not found"
                    }
                }
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }
}
