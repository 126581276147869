import { getDocs, collection, getDoc, doc, query, where, orderBy } from "firebase/firestore";
import { db } from "../../firebase/firebase";

async function hashTicket(ticket) {
    let hashHex = "";
    try {
        const file = await readUploadedFileAsText(ticket);
        const encoder = new TextEncoder();
        const fileBuffer = encoder.encode(file);
        const hashBuffer = await crypto.subtle.digest('SHA-1', fileBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
        return hashHex;
    } catch (error) {
        console.log(error);
        return hashHex;
    }
}

const readUploadedFileAsText = (inputFile) => {
    const temporaryFileReader = new FileReader();
  
    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };
  
      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsText(inputFile);
    });
  };

function listingToObject(doc) {
    return {
        key: doc.id,
        eventId: doc.data().event,
        price: (Math.round(doc.data().price) / 100).toFixed(2),
        quantity: doc.data().quantity,
        type: doc.data().type,
        description: doc.data().description,
        createdAt: doc.data().createdAt.seconds * 1000,
        createdBy: doc.data().createdBy,
        tickets: doc.data().tickets,
        verified: doc.data().verified,
        inCart: doc.data().inCart,
        sold: doc.data().sold,
        fee: doc.data().fee,
        expiry: doc.data().expiry.seconds * 1000,
    }
}

function cartToObject(doc) {
    return {
        key: doc.id,
        listing: doc.data().listing,
        event: doc.data().event,
        rawPrice: (Math.round(doc.data().rawPrice) / 100).toFixed(2),
        price: (Math.round(doc.data().price) / 100).toFixed(2),
        fee: (Math.round(doc.data().fee) / 100).toFixed(2),
        eventTitle: doc.data().eventTitle,
        eventStart: doc.data().eventStart.seconds * 1000,
        tickets: doc.data().tickets,
        venue: doc.data().venue,
        type: doc.data().type,
        seller: doc.data().seller
    }
}

export default class ListingFetchModel {
    static async fetchListings(eventId) {
        try {
            const q = query(collection(db, "listing"), where('event', '==', eventId), where('verified', "==", true));
            const querySnapshot = await getDocs(q);
            let listings = querySnapshot.docs.map((doc) => listingToObject(doc));
            return {
                success: true,
                data: listings
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    static async fetchListing(id) {
        try {
            const docSnapshot = await getDoc(doc(db, "listing", id));
            let listing = listingToObject(docSnapshot);
            return {
                success: true,
                data: listing
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    static async fetchCartItems(user) {
        try {
            if (!user) return {
                success: false,
                error: "No user provided"
            }
            const docRef = collection(db, "user", user.uid, "cart");
            const docSnapshot = await getDocs(docRef);
            try {
                let cart = docSnapshot.docs.map((doc) => cartToObject(doc));
                return {
                    success: true,
                    data: cart,
                    total: cart.reduce((total, item) => total + item.price * item.tickets.length, 0)
                }
            } catch (error) {
                return {
                    success: false,
                    error: error
                }
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    static async checkTimer(user) {
        try {
            if (!user) return {
                success: false,
                error: "No user provided"
            }
            const docRef = doc(db, "timer", user.uid);
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
                let startAt = docSnapshot.data().startAt.seconds * 1000;
                let seconds =  docSnapshot.data().seconds;
                if (seconds === 0) return { success: true, data: 0 };
                let now = new Date().getTime();
                let timeLeft = (seconds * 1000) - (now - startAt);
                if (timeLeft > 0) {
                    return {
                        success: true,
                        data: timeLeft
                    }
                } else {
                    return {
                        success: false,
                        error: "expired"
                    }
                }
            } else {
                return {
                    success: false,
                    error: "Timer not found"
                }
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }



    static async fetchUserListings(user) {
        try {
            if (!user) {
                return {
                    success: false,
                    error: "No user provided"
                }
            }
            const docRef = query(collection(db, "listing"), where("createdBy", "==", user.uid), orderBy("createdAt", "desc"));
            const docSnapshot = await getDocs(docRef);
            try {
                let listings = docSnapshot.docs.map((doc) => listingToObject(doc));
                //Add a for loop to categorise each listing into the corresponding events.
                return {
                    success: true,
                    data: listings
                }
            } catch (error) {
                return {
                    success: false,
                    error: error
                }
            }
        }
        catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    static async verifyTickets(uid, tickets) {
        try {
            let succeeded = [];
            let failed = [];
            let hashed = {};
            for (let i = 0; i < tickets.length; i++) {
                let ticket = tickets[i];
                let hash = await hashTicket(ticket);
                if (hash === "") {
                    return {
                        success: false,
                        error: "Hashing failed"
                    }
                } else {
                    if (hashed[hash]) {
                        failed.push(ticket);
                    } else {
                        hashed[hash] = ticket;
                        let docRef = doc(db, "hashes", hash);
                        let docSnapshot = await getDoc(docRef);
                        if (docSnapshot.exists()) {
                            if (docSnapshot.data().allowed === uid) {
                                succeeded.push(ticket);
                            } else {
                                failed.push(ticket);
                            }
                        } else {
                            succeeded.push(ticket);
                        }
                    }
                }
            }

            return {
                success: true,
                succeeded: succeeded,
                failed: failed
            }
        } catch (error) {
            return {
                success: false,

                error: error
            }
        }
    }
}

export { listingToObject };