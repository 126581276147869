import React from 'react';
import Features from './Features';
import { ReactComponent as AppleSvg } from '../images/apple-badge.svg';

export default function MobileContent() {
    return (
        <div className="mobile-content">
            <div className="mobile-hero">
                <h1 className="hero-title">Buy and Sell Tickets</h1>
                <span className="feature-description">Download our app on App Store or Google Play store to start buying or selling club tickets!</span>
            </div>
            <ul className="footer-list">
              <a href='/'><li className="footer-item">
                  <AppleSvg />
                </li></a>
              <a href='/'><li className="footer-item"><img className="badge" src={require("../images/google-play-badge.png")} alt="google-play-"></img></li></a>
            </ul>
            <Features />
        </div>
    )
}