// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore"
import { getAuth } from "firebase/auth";
import { getRemoteConfig } from "firebase/remote-config"

const firebaseConfig = {
  apiKey: "AIzaSyBQsN4QM9XlYtqPuteSht4sF0-bVeDyvNA",
  authDomain: "entick-211c9.firebaseapp.com",
  projectId: "entick-211c9",
  storageBucket: "entick-211c9.appspot.com",
  messagingSenderId: "838861652691",
  appId: "1:838861652691:web:a6f30dd4e4635769ae4a26",
  measurementId: "G-8C5PSCELNN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const remoteConfig = getRemoteConfig(app);

remoteConfig.defaultConfig = {
  "kill": false
}