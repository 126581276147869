import React from "react";
import './Maintenance.css'

const Maintenance = () => {
    return (
        <div className="container">
            <h1 className="header1">Entick Marketplace is currently under maintenance please come back later.</h1>
            <h2 className="header2">We are trying to make the marketplace better for you.</h2>
            <h3 className="header3">We apologise for the inconvenience</h3>
        </div>
    )
}

export default Maintenance;