import React from 'react';
import '../index.css';

export default function Notfound() {
    return (
        <section className="main">
        <div className="event-page">
            <h1 className="cart-title">404</h1>
            <h2 className="not-found-subtitle">Page not found</h2>
        </div>
        </section>
    )
}