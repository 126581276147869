import React, { useEffect, useState } from 'react';
import '../index.css';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../firebase/auth';
import { UserIcon, TicketIcon, ListIcon, CreditCardIcon, RightFromBracket, CartIcon } from './Icons/Icons';
import {isBrowser, isMobile} from 'react-device-detect';
import { auth } from '../firebase/firebase';

export default function Header(props) {
  const { authUser, signOut } = useAuth();
  const [dropdown, setDropwdown] = useState((<div className="dropdown-content">
  <Link to="/profile/account" ><UserIcon/> Account</Link>
  <Link to="/profile/mytickets"><TicketIcon/> My Tickets</Link>
  <Link to="/profile/mylistings"><ListIcon/> My Listings</Link>
  <Link to="/profile/payouts"><CreditCardIcon/> Payment</Link>
  <Link to="" onClick={signOut}><RightFromBracket/> Logout</Link>
</div>));
  const navigate = useNavigate();

  function redirectToPage(page) {
      navigate(page);
      window.scrollTo(0, 0);
  }

  useEffect(() => {
    if (auth.currentUser) {
      if (auth.currentUser.isAnonymous) {
        setDropwdown((<div className='dropdown-content'>
        <Link to="/profile/mytickets"><TicketIcon/> My Tickets</Link>
        <Link to="" onClick={signOut}><RightFromBracket/> Logout</Link>
      </div>));
      } else {
        setDropwdown((<div className="dropdown-content">
        <Link to="/profile/account" ><UserIcon/> Account</Link>
        <Link to="/profile/mytickets"><TicketIcon/> My Tickets</Link>
        <Link to="/profile/mylistings"><ListIcon/> My Listings</Link>
        <Link to="/profile/payouts"><CreditCardIcon/> Payment</Link>
        <Link to="" onClick={signOut}><RightFromBracket/> Logout</Link>
      </div>));
      }
    }
  }, [auth.currentUser])


  if (isBrowser) {
    return (
      <header>
        <nav className="navbar" id="navbar">
          <Link className="navbar-brand" to="/">Entick</Link>
          <ul className="navbar-list">
          <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/discover">Discover</Link>
            </li>
            <li className={`${authUser ? "nav-item" : "hidden"}`}>
              <Link className="nav-link" aria-current="page" to="/cart"><CartIcon/></Link>
            </li>
            <li className={`${authUser ? "hidden" : "nav-item"}`}>
              <Link to="/login" className={`${authUser ? "hidden" : "nav-link"}`} type="button" onClick={signOut} >Log in</Link>
            </li>
            <li className={`${!authUser ? "hidden" : "nav-item"}`} id="right-pad-zero">
            <div className="dropdown">
              <button className="dropbtn"> 
                <img className="profile-button" src={require("../images/user.png")}></img>
              </button>
              {dropdown}
            </div> 
            </li>
            <li className="nav-item">
              <a><button className="login-button" type="button" onClick={() => redirectToPage("/sell")} >Start Selling</button></a>
            </li>
          </ul>
        </nav>
      </header>
    ) } else if (isMobile) {
      return (
        <header>
        <nav className="mobile-navbar">
          <Link className="mobile-navbar-brand" to="/">Entick</Link>
        </nav>
      </header>
      )
    }
  }
