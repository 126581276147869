import React, { useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";
import { useAuth } from "../firebase/auth";
import ListingFetchModel from "../models/getData/ListingFetchModel";
import ListingPostModel from "../models/postData/ListingPostModel";
import Transaction from "../models/postData/Transaction";
import CheckoutItem from "./CheckoutItem";
import Skeleton from "./Skeleton/Skeleton";
import {loadStripe} from '@stripe/stripe-js';
import "../index.css";
import { getAnalytics, logEvent } from "firebase/analytics";

//change with constants
const stripePromise = loadStripe('pk_test_51MRYnhKDi5Owm7gVf12Z41RZnYkdmaXDfNTymHD9XMmQQ0LfehrkwNxhOTopNe8j2slkMV8BumRzbeuCuFF4QEwQ00PWl2CzTR');

export default function Cart({handleShowToast}) {
    const { authUser, isLoading } = useAuth();
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const analytics = getAnalytics();
    const [cart, setCart] = useState([{
        event: "",
        eventStart: "",
        eventTitle: "",
        listing: "",
        price: 0,
        tickets: []
    }]);
    const [total, setTotal] = useState(0);

    const getCart = async () => {
        ListingFetchModel.fetchCartItems(authUser).then((response) => {
            if (response.success) {
                setCart(response.data);
                setTotal(response.total.toFixed(2));
                checkTime(response.data);
            }
            setLoading(false);
        })
    }

    function handleRefresh () {
        setRefresh(!refresh);
    }

    const checkTime = async (cartItems) => {
        const response = await ListingFetchModel.checkTimer(authUser)
        if (!response.success) {
            if (response.error === "expired") {
                await clearCart(cartItems);
                handleShowToast("Your cart has expired", "failure");
            }
        } else {
            // if (response.data) {
            //     console.log(response.data);
            // }
        }
    }

    const clearCart = async (cartItems) => {
        ListingPostModel.clearCart(authUser, cartItems).then((response) => {
            if (response.success) {
                handleShowToast("Cart cleared", "success");
                resetTimer();
            } else {
                console.log("ClearCart: " + response.error);
            }
        })
    }

    const resetTimer = async () => {
        const res = await ListingPostModel.resetTimer(authUser);
        if (res.success) {
            handleRefresh();
        } else {
            handleShowToast("Something went wrong, please refresh and try again", "failure");
        }
    }
    

    const cartItems = cart.map((item) => {
        return (
            <li className="cart-list-item">
                <CheckoutItem key={item.id} item={item} user={authUser} handleShowToast={handleShowToast} handleRefresh={handleRefresh}/>
            </li>
        )
    })

    const buyCart = async () => {
        setLoading(true);
        const response = await ListingFetchModel.checkTimer(authUser);
        if (response.success && response.data > 0) {
            let amount = Math.round(total * 100);
            let response = await Transaction.getPaymentIntentClientSecret(amount, "gbp");
            if (response.success) {
                logEvent(analytics, 'begin_checkout');
                navigate("/cart/checkout?payment_intent_client_secret=" + response.data.clientSecret);
                setLoading(false);
            } else {
                console.log(response.error);
                setLoading(false);
            }
        } else {
            clearCart();
            handleShowToast("Your cart is empty or expired", "failure");
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!isLoading && authUser) {
            getCart();
        }
    }, [isLoading, authUser, refresh]
    )

    return (
        <section className="main">
            {loading ? <Skeleton Type="circular"/> : (
                <div className="event-page">
                <div className="cart-top">
                    <h1 className="cart-title">Cart</h1>
                    <p className="cart-description">Your tickets are reserved for 10 minutes...So be quick!</p>
                </div>
                <div className="cart">
                    <div className="cart-container">
                        <ol className="cart-list">
                            {authUser ? cartItems : <p key="notloggedin-cart" >Please login to view your cart</p>}
                            {cart.length === 0 ? <p className="cart-description" key="empty-cart">Your cart is empty</p> : <p></p>}
                        </ol>
                    </div>
                    <div className="cart-checkout-container">
                        <div className="cart-checkout">
                            <span className="cart-checkout-total">Total: £{total}</span>
                            {/* Add a question mark where once you hover over/click on it you see the price breakdown with the fees */}
                            <span className="cart-checkout-description">the total includes the Entick fees</span>
                        </div>
                        {/* Add here payment methods such as apple pay, paying with debit card and more using stripe */}
                        <button className="cart-checkout-button" onClick={buyCart} disabled={loading}>Continue</button>
                    </div>
                </div>
            </div>
            )}
        </section>
    )
    
}