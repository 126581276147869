import Transaction  from "../models/postData/Transaction";
import React, { useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../firebase/auth";
import Authenticator from "../models/Authenticator";
import Skeleton from "./Skeleton/Skeleton";
import { auth } from "../firebase/firebase";


export default function SellerCheck({handleShowToast}) {
    const { authUser, isLoading } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        username: "",
        seller: false,
        termsAccepted: false,
        email: "",
        avgRating: 0,
        numReviews: 0,
        connectedAccount: false,
        connectedAccountId: "",
        stripeCustomerId: ""
    });

    const getUserData = async () => {
        Authenticator.getUserData(authUser).then((response) => {
            if (response.success) {
                response.data.email = authUser.email;
                setUserData(response.data);
                handleShowToast("Be patient, this might take a minute", "warning");
                Transaction.getConnectedAccount(response.data.connectedAccountId).then((res) => {
                    if (res.success) {
                        if (res.account.detailsSubmitted) {
                            Transaction.completeConnectedAccountVerification(authUser, res.account.id).then((res) => {
                                if (res.success) {
                                    handleShowToast("Congratulations, You are now a verified seller!", "success");
                                    setLoading(false);
                                    navigate("/sellTickets/event");
                                    window.scrollTo(0, 0);
                                } else {
                                    handleShowToast("Error verifying account. PLease try again", "error");
                                    setLoading(false);
                                    navigate("/sell");
                                    window.scrollTo(0, 0);
                                }
                            })
                            setLoading(false);
                        } else {
                            handleShowToast("Your details are incomplete. Please start over", "failure");
                            setLoading(false);
                            navigate("/sell");
                            window.scrollTo(0, 0);
                        }
                    } else if (res.error){
                        handleShowToast("Your details are incomplete. Please start over", "failure");
                        setLoading(false);
                        navigate("/sell");
                        window.scrollTo(0, 0);
                    } else {
                        handleShowToast("There is an error in the system. Please try again later", "warning");
                        setLoading(false);
                        navigate("/sell");
                        window.scrollTo(0, 0);
                    }
                })
            } else {
                handleShowToast("Error getting user data", "error");
                setLoading(false);
                navigate("/sell");
            }
        })
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        if (!isLoading && authUser) {
            getUserData();
        }
    }, [isLoading, authUser]);

    return (
        <section className="main">
            <div className="event-page">
                <Skeleton Type="logo"/>
                <Skeleton Type="circular"/>
            </div>
        </section>
    )
}