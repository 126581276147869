import { getDocs, collection, getDoc, doc, where, query } from "firebase/firestore";
import { db } from "../../firebase/firebase";
// import { Auth } from "firebase/auth";

function eventToObject(doc) {
    return {
        key: doc.id,
        name: doc.data().name,
        start: doc.data().start.seconds * 1000,
        end: doc.data().end.seconds * 1000,
        venue: doc.data().venue,
        description: doc.data().description,
        entryRequirements: doc.data().entryRequirements,
        createdBy: doc.data().createdBy,
        createdAt: doc.data().createdAt.seconds * 1000,
        views: doc.data().views,
        ticketNo: doc.data().tickets,
        expiry: doc.data().expiry.seconds * 1000,
    }
}

export default class EventFetchModel {
    static async fetchEvents() {
        try {
            const querySnapshot = await query(collection(db, "event"), where("expiry", ">", new Date()));
            const snapshot = await getDocs(querySnapshot);
            let events = snapshot.docs.map((doc) => eventToObject(doc));
            return {
                success: true,
                data: events
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    static async fetchEvent(id) {
        try {
            const docSnapshot = await getDoc(doc(db, "event", id));
            let event = eventToObject(docSnapshot);
            if (event.expiry > new Date()) {
                return {
                    success: true,
                    data: event
                }
            } else {
                return {
                    success: false,
                    error: "Event expired"
                }
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    static async fetchLocationEvents(location) {
        try {
            const querySnapshot = await query(collection(db, "event"), where("venue", "==", location), where("expiry", ">", new Date()));
            const snapshot = await getDocs(querySnapshot);
            let events = snapshot.docs.map((doc) => eventToObject(doc));
            return {
                success: true,
                data: events
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }
}

export { eventToObject};